import { useEffect } from "react";
import { usePianoErrorHandler } from "~/features/piano/error-handling";
import { useUserInfoDataLayerSync } from "~/features/piano/user-info";
import { usePushEcommEventsToDataLayer } from "~/lib/analytics/checkout";
import { pushDataLayer } from "~/lib/analytics/datalayer";
import { useCartAutoCheckout } from "~features/chargebee/hooks/use-cart";
import { useTrackInstitutionalAccess } from "~features/institutional/tracking";

function initAds(config) {
  if (config) {
    window.__ads.push(["page", config]);
  }
}

function initDataLayer(content) {
  if (content) {
    pushDataLayer(content, false);
  } else {
    console.warn(`🔥 NO DATALAYER PRESENT`);
  }
}

let initialized = false;

/**
 * Page Component
 *
 * This component runs on every page and initializes the data layer and ads.
 * It has access to all the app's context providers, which means we can use state hooks,
 * namely usePiano, usePageContext, and useUserInfo.
 */
export function Page({ children, pageData, dataLayerContent }) {
  // Initialize data layer on first render
  useEffect(() => {
    if (initialized) return;

    initDataLayer(dataLayerContent);
    initAds(pageData.adsConfig);
    initialized = true;
  }, []);

  // Handle checkout events
  usePushEcommEventsToDataLayer();

  // Handle Piano script load errors
  usePianoErrorHandler();

  // Call hook to initialize trackig pixel
  useTrackInstitutionalAccess();

  // Pushes user info to the dataLayer when user info is available
  // Currently only executes once per page load
  useUserInfoDataLayerSync();

  // Attempts to open checkout modal if user has items in cart
  useCartAutoCheckout();

  return children;
}

export default Page;
