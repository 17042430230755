// @ts-check
import { useEffect, useRef } from "react";

import { plans } from "@sciam/chargebee/plans.json";
import { prices } from "@sciam/chargebee/prices.json";
import { pushDataLayer } from "~/lib/analytics/datalayer";
import { useSciAmJwtSync } from "~features/piano/hooks/use-piano-auth-sync";

/**
 * Checkout hook that doesn't return anything. Solely to add event listeners
 * The checkout flow is in an iframe modal, but our architecture will emit custom events to the parent page.
 * We set up event listeners on those custom events.
 */
export function usePushEcommEventsToDataLayer() {
  const { payload } = useSciAmJwtSync();
  const userId = payload ? payload["https://sciam.com/user_id"] : null;

  // For unknown reasons, useState hook doesn't work here, but useRef hook does.
  // useRef works for state data not needed for rendering, so it works out.
  const checkoutStateRef = useRef({});

  function onCheckoutStart(e) {
    let dlObj = {};

    // Get user ID from jwt
    dlObj["userId"] = userId;

    // Get specific relevant data from the event.detail
    dlObj["couponCode"] = e.detail.couponCode || "";
    dlObj["customFields"] = e.detail.customFields;
    dlObj["period"] = e.detail.period || "";
    dlObj["planId"] = e.detail.planId;
    dlObj["priceId"] = e.detail.priceId;
    dlObj["quantity"] = e.detail.quantity;
    // Note: price will be derived in the checkoutSuccess() function

    // Store complete object in useRef
    checkoutStateRef.current = dlObj;
    checkoutModalStarted();
  }

  useEffect(() => {
    // Watch for any events from the checkout modal and report to GTM

    // When the checkout modal opens, store its checkout params into hook state
    window.addEventListener("chargebee:checkout-start", onCheckoutStart);

    /* There are several screens in the step event. */
    window.addEventListener("chargebee:checkout-step", checkoutModalStep);

    /* Checkout modal close by user; there is no auto-close; the user must close the modal */
    window.addEventListener("chargebee:checkout-close", userClosedCheckoutModal);

    // When purchase is successful, save the checkout state to localStorage
    const onCheckoutSuccess = () => saveCheckoutState(checkoutStateRef.current);
    window.addEventListener("chargebee:checkout-success", onCheckoutSuccess);

    // Check for stored checkout success data on page load
    handleStoredCheckoutSuccess();

    return () => {
      // Remove on unmount to prevent chaos during development
      window.removeEventListener("chargebee:checkout-start", onCheckoutStart);
      window.removeEventListener("chargebee:checkout-step", checkoutModalStep);
      window.removeEventListener("chargebee:checkout-close", userClosedCheckoutModal);
      window.removeEventListener("chargebee:checkout-success", onCheckoutSuccess);
    };
  }, [userId]);
}

// localStorage name to hold the datalayer properties
const PURCHASE_COMPLETE_DATA_KEY = "_sciam.purchase.completed";
function saveCheckoutState(data) {
  // The Chargebee SDK will redirect after purchase and lose our useRef() data. Plop data into localStorage and read on the next pageview.
  localStorage.setItem(PURCHASE_COMPLETE_DATA_KEY, JSON.stringify(data));
}
function handleStoredCheckoutSuccess() {
  // Don't run on pages that auto-redirect to avoid race condition with retrieving from localStorage
  // TODO: Use the page bundle to inform when we're on a redirect page
  if (["/welcome/", "/thank-you/", "/success/"].includes(window.location.pathname)) {
    return;
  }

  let data = null;
  try {
    data = JSON.parse(localStorage.getItem(PURCHASE_COMPLETE_DATA_KEY));
  } catch {}

  if (data) {
    checkoutSuccess(data);
    localStorage.removeItem(PURCHASE_COMPLETE_DATA_KEY);
  }
}

// Get plan internal name from JSON
function getPlanName(planId) {
  let plan = plans.find((element) => {
    return element.id === planId;
  });

  return plan.name;
}

// Get plan price from JSON
function getPlanPrice(priceId) {
  let planPrice = prices.find((element) => {
    return element.id === priceId;
  });

  // Convert to whole dollars
  return (planPrice.price || 0) / 100;
}

/**
 * Update the dataLayer with ecommerce data
 * With Chargebee, this is only relevant on the purchase event.
 *
 * @param {(
 *  "add_payment_info"|
 *  "add_shipping_info"|
 *  "begin_checkout"|
 *  "purchase"|
 *  "select_item"
 * )} eventName - GTM event to push.
 */
function pushEcommDataLayer(eventName, data) {
  const planName = getPlanName(data.planId);
  const planPrice = getPlanPrice(data.priceId);

  let content = {
    event: eventName,
    // Google values
    ecommerce: {
      currency: "USD",
      value: planPrice,
      coupon: data.coupon,
      items: [
        {
          item_id: data.planId,
          item_name: planName,
          price: planPrice,
          quantity: 1,
        },
      ],
    },
  };

  pushDataLayer(content);
}

// Each checkout step in the flow has the same custom event prefix.
// This function checks the suffix part to determine the step.
export function checkoutModalStep(e) {
  const eventName = `chargebeeCheckout_screen_${e.detail}`;

  pushDataLayer({ event: eventName });
}

export function checkoutModalStarted() {
  const content = {
    event: "chargebeeCheckoutStarted",
  };

  pushDataLayer(content);
}

export function userClosedCheckoutModal() {
  const content = {
    event: "chargebeeUserClosedCheckoutModal",
  };

  pushDataLayer(content);
}

export function checkoutSuccess(data) {
  const planPrice = getPlanPrice(data.priceId);

  const content = {
    event: "chargebeeCheckoutSuccess",
    user: {
      userId: data.userId,
    },
    checkout: {
      planId: data.planId,
      priceId: data.priceId,
      price: planPrice,
      customFields: data.customFields,
      period: data.period,
      couponCode: data.couponCode,
      quantity: data.quantity,
    },
  };

  pushDataLayer(content);
  pushEcommDataLayer("purchase", data);
}
