import { useEffect } from "react";
import { useAuth } from "~/features/auth";
import { pushDataLayer } from "~/lib/analytics/datalayer";
import { useEntitlements } from "~features/access";

let pushedUserInfo = false;

// @TODO: We really need to stop copy-pasting this function and point them all to @sciam/shared
function readCookie(name) {
  var nameEQ = name + "=";
  var ca = document.cookie.split(";");
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) == " ") c = c.substring(1, c.length);
    if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
  }
  return null;
}

function readJsonCookie(name) {
  try {
    return JSON.parse(readCookie(name));
  } catch (e) {
    return null;
  }
}

const getBpid = () =>
  readCookie("idp_magic") ||
  readCookie("ezproxy_bpid") ||
  readJsonCookie("idp_details")?.BPID ||
  null;

/**
 * React hook to push user info to the dataLayer when user info is available.
 *
 * It serves the same purpose as `pushUserInfoToDatalayer()` in `piano-mura.js`
 *
 * See also `~core/Page.jsx`
 */
export function useUserInfoDataLayerSync() {
  const { isLoggedIn, user, isLoading } = useAuth();
  const { hasSub, hasAccess, hasUnlimitedAccess, hasInstitutionalAccess, primaryEntitlement } =
    useEntitlements();

  useEffect(() => {
    if (
      // Don't do anything if Auth0 is still loading.
      isLoading ||
      // `hasSub` is undefined until we know the user's access status.
      typeof hasSub === "undefined"
    )
      return;

    const BPID = hasInstitutionalAccess ? getBpid() : undefined;

    // Update Sentry user context when user info changes
    window?.__SENTRY__?.hub?.setUser?.({
      ip_address: "{{auto}}",
      id: user?.uid || undefined,
      email: user?.email || undefined,
      bpid: BPID || undefined,
      has_sub: hasSub,
      has_idp: hasInstitutionalAccess,
      has_access: hasAccess,
      has_unlimited_access: hasUnlimitedAccess,
    });

    // Push latest user state to Chartbeat
    // https://docs.chartbeat.com/cbp/tracking/standard-websites/subscriber-engagement
    const cbUserType = hasSub ? "paid" : isLoggedIn ? "lgdin" : "anon";
    window._cbq?.push(["_acct", cbUserType]);

    // Don't push to dataLayer more than once
    if (pushedUserInfo) return;

    // This useEffect runs on every page, including callback. We only want to run on non-callback pages
    // If we see this storage item, then we know the user had immediately logged in. Fire the login event and remove
    // the storage item. Only supporting localStorage method.
    if (window.location.pathname !== "/callback/") {
      let authEvent = JSON.parse(localStorage.getItem("sa_auth_event"));

      if (authEvent === 1) {
        pushDataLayer({
          event: "login",
          user: {
            userId: user?.uid || "",
          },
        });
      }

      localStorage.removeItem("sa_auth_event");

      // Ensure identify backfill
      // We attempt to identify on signin and register. If that fails or the user is forgotten, this will fill in the gaps.
      let isIdentified = localStorage.getItem("sa_identified");

      // If the user uid exists, mark as identified
      if (isLoggedIn && !isIdentified && user?.uid) {
        localStorage.setItem("sa_identified", 1);

        pushDataLayer({
          event: "sciam_identified",
          user: {
            userId: user.uid,
          },
        });
      }

      // If the user has signed out, clear this to ensure we'll re-identify next time they sign in
      if (!isLoggedIn) {
        localStorage.removeItem("sa_identified");
      }
    }

    const dataLayerObj = {
      event: "userInfo",
      user: {
        // User
        userId: user?.uid || "",
        isLoggedIn: !!isLoggedIn,
        isRegistered: !!isLoggedIn,

        // Subscription
        isSubscriber: !!hasSub,
        subscriptionName: primaryEntitlement?.id || undefined,
        subscriptionType: primaryEntitlement?.name || undefined,

        // IDP
        isSiteLicenseCustomer: !!hasInstitutionalAccess,
        siteLicenseBPID: BPID || undefined,
      },
    };

    pushDataLayer(dataLayerObj);
    pushedUserInfo = true;
  }, [isLoading, hasSub, hasInstitutionalAccess]);
}
